/* Global Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
  }
  
  /* App Container */
  .App {
    text-align: center;
    padding: 20px;
  }
  
  /* Header */
  .App-header {
    background-color: #1db954;
    color: white;
    padding: 1rem;
  }
  
  /* Buttons */
  button {
    background-color: #1db954;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #1ed760;
  }
  
  /* Login Component */
  .login {
    margin: 20px;
  }
  
  .login input {
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
  
  /* Playlist Prompt Form */
  .playlist-prompt-form {
    margin: 20px;
  }
  
  .playlist-prompt-form textarea {
    width: 80%;
    max-width: 600px;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  /* Playlist Editor */
  .playlist-editor {
    margin: 20px;
  }
  
  .playlist-editor ul {
    list-style: none;
    padding: 0;
  }
  
  .track-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin: 0.5rem auto;
    padding: 1rem;
    max-width: 600px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  /* Playlist Confirmation */
  .playlist-confirmation {
    margin: 20px;
  }
  
  .playlist-confirmation input,
  .playlist-confirmation textarea {
    padding: 0.5rem;
    margin: 0.5rem;
    width: 80%;
    max-width: 600px;
  }
  
  /* Spotify Connect Button additional styling */
  .spotify-connect-button {
    font-weight: bold;
    font-size: 1.1rem;
  }
  